<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    class="text-capitalize text-body-1"
    @click="scrollToSection(getSection())"
    :to="getRoute()"
  >
    {{ callToAction }}
    <v-icon x-small class="ml-4" v-if="hasIcon">$chevronRight</v-icon>
  </v-btn>
</template>

<script>
import scrollToMixin from '@/mixins/scrollToMixin'
export default {
  name: 'HActionButton',
  mixins: [scrollToMixin],
  props: {
    callToAction: {
      type: String
    },
    hasIcon: {
      type: Boolean,
      default: false
    },
    selectedTab: {
      type: Number
    }
  },
  computed: {
    selection() {
      return typeof this.selectedTab === 'number'
        ? this.selectedTab
        : this.sections.length - 1
    }
  },
  methods: {
    clickedTab(value) {
      this.$emit('clickedTab', value)
    },
    setTab(value) {
      this.$emit('setTab', value)
    },
    getSection() {
      this.setTab(this.selection)
      this.clickedTab(true)
      return this.sections[this.selection].id
    },
    getRoute() {
      return this.$route.meta.static ? `/${this.getSection()}` : ''
    }
  }
}
</script>

<style scoped lang="scss"></style>
